import { defineStore } from 'pinia'
import type { PaginationStats } from '@/types/paginationStats.type'
import type {
  CreateUserPayload,
  GetUsersQuery,
  UpdateUserPreferencesPayload,
  User
} from '@/types/user.type'
import type { _AxiosError } from './api'
import axios from './api'
import * as Sentry from '@sentry/vue'
import { _ElMessage } from '@/utils/element-plus-wrapper'

interface State {
  current_user: User | null
  users: User[]
  users_stats: PaginationStats | null
  is_google_linked: boolean
}

export const useUserStore = defineStore('userStore', {
  state: (): State => ({
    current_user: null,
    users: [],
    users_stats: null,
    is_google_linked: false
  }),
  getters: {
    get_current_user(state): User {
      if (!state.current_user) {
        throw new Error('Missing: Login user.')
      }
      return state.current_user
    },
    get_user_by_id:
      (state) =>
      (id: number): User | undefined => {
        return state.users.find((e) => e.id === id)
      },
    is_supplier(state): boolean {
      return state.current_user ? state.current_user.isSupplier : false
    },
    is_concierge_expanded(state): boolean {
      if (state.current_user?.preferences) {
        return state.current_user.preferences.eveConciergeExpanded || false
      } else {
        return false
      }
    },
    concierge_thread_id(state): number | null {
      if (state.current_user?.preferences) {
        return state.current_user.preferences.eveConciergeThreadId || null
      } else {
        return null
      }
    },
    should_change_password(state): boolean {
      if (!state.current_user) {
        throw new Error('Missing: Login user.')
      }
      return state.current_user.requiredUpdatePassword
    }
  },
  actions: {
    set_current_user(data: User) {
      this.current_user = data
      Sentry.setUser({
        id: data.id,
        space: data.space.name,
        username: data.name,
        email: data.email
      })
    },
    clear_current_user() {
      this.current_user = null
      Sentry.setUser(null)
    },
    get_users(json: GetUsersQuery) {
      axios
        .get('/users', { params: json })
        .then((res) => {
          this.$patch((state) => {
            state.users = res.data.items
            state.users_stats = res.data.meta
          })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
        })
    },
    get_user(id: number) {
      return new Promise<User>((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    clear_users() {
      this.$patch((state) => {
        state.users = []
        state.users_stats = null
      })
    },
    get_users_count(spaceId: number) {
      return new Promise<number>((resolve, reject) => {
        axios
          .get('/users', { params: { spaceId } })
          .then((res) => {
            resolve(res.data.meta.totalItems)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    create_user(json: CreateUserPayload, skipSave = false) {
      return new Promise<User>((resolve, reject) => {
        axios
          .post('/users', json)
          .then((res: { data: User }) => {
            _ElMessage({ type: 'success', message: 'ユーザを発行しました' })
            if (!skipSave) {
              this.$patch((state) => {
                state.users.push(res.data)
              })
            }
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: 'ユーザの発行に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    resend_email_verification(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/users/${id}/resendSignupLocalMail`)
          .then(() => {
            _ElMessage({ type: 'success', message: '認証メールを再送信しました' })
            resolve()
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: '認証メールの再送信に失敗しました' })
            reject(err)
          })
      })
    },
    update_user_name(json: { id: number } & Partial<Pick<User, 'name'>>) {
      const { id, ...payload } = json
      return new Promise<void>((resolve, reject) => {
        axios
          .put('/users/' + id, payload)
          .then((res: { data: User }) => {
            _ElMessage({ type: 'success', message: 'ユーザ名を更新しました' })
            this.$patch((state) => {
              update_user(state, res.data)
            })
            resolve()
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: 'ユーザ名の更新に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    update_user_password(json: { id: number; password: string }) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put('/users/' + json.id + '/password', { password: json.password })
          .then((res: { data: User }) => {
            _ElMessage({ type: 'success', message: 'パスワードを更新しました' })
            this.$patch((state) => {
              update_user(state, res.data)
            })
            resolve()
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: 'パスワードの更新に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    update_user_preferences(json: UpdateUserPreferencesPayload) {
      axios
        .put('/users/' + json.id + '/preferences', { preferences: json.preferences })
        .then((res: { data: User }) => {
          this.$patch((state) => {
            update_user(state, res.data)
          })
        })
        .catch((err: _AxiosError) => {
          console.log(err)
        })
    },
    deactivate_user(id: number) {
      return new Promise<User>((resolve, reject) => {
        axios
          .post('/users/' + id + '/deactivate')
          .then((res: { data: User }) => {
            _ElMessage({ type: 'success', message: 'ユーザを利用停止しました' })
            this.$patch((state) => {
              update_user(state, res.data)
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: 'ユーザの利用停止に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    get_google_auth_status() {
      return new Promise<{ isLinked: boolean }>((resolve, reject) => {
        axios
          .get(`/google/status`)
          .then((res) => {
            this.$patch((state) => {
              state.is_google_linked = res.data.isLinked
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    start_google_auth() {
      return new Promise<{ authUrl: string }>((resolve, reject) => {
        axios
          .get(`/google/startAuth?channel=webChat`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    revoke_google_auth() {
      return new Promise<void>((resolve, reject) => {
        axios
          .post('/google/revokeAuth')
          .then(() => {
            _ElMessage({ type: 'success', message: 'Googleアカウントの連携を解除しました' })
            this.$patch((state) => {
              state.is_google_linked = false
            })
            resolve()
          })
          .catch((err: _AxiosError) => {
            _ElMessage({ type: 'error', message: 'Googleアカウントの連携解除に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    }
  }
})

function update_user(state: State, data: User) {
  const idx = state.users.findIndex((e) => e.id === data.id)
  if (idx !== -1) {
    state.users[idx] = data
  }
  if (state.current_user) {
    if (state.current_user.id == data.id) {
      state.current_user = data
    }
  }
}
